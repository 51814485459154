import React from 'react';
import { graphql } from 'gatsby';
import {
  BIO, CALL_TO_ACTION, ANNOUNCEMENTS, TRANSITION_TEAM, COMMUNITY_SURVEY, PRIORITIES,
} from '@constants';
import {
  Layout,
} from '@molecules';
import {
  AnnouncementsSection, BioSection, CallToActionSection, CommunitySurveySection,
  PrioritiesSection, HomepageHero, TransitionTeamSection,
} from '@organisms';
import { setSearchParams, getKeyValue } from '@utils';

const ContentHomepageTemplate = ({ location, data, pageContext }) => {
  const {
    site, page, settings, languageLabels,
  } = data;

  const { locale } = pageContext;
  const { siteTitle, socialLinks, sharedLabels } = settings;
  const { social } = getKeyValue(site, 'siteMetadata') || {};
  const {
    metaTitle, metaDescription, metaImage, path, pageContent,
    disableAlertBar, enLanguagePageLinks, pageInOtherLanguages,
  } = page || {};
  const {
    heroTitle, heroSubheading, heroImage, heroCallToAction,
    heroSlogan, homepageSections,
  } = pageContent;
  const { url: metaImageUrl } = getKeyValue(metaImage, 'file') || {};
  const sectionModules = {
    [ANNOUNCEMENTS]: AnnouncementsSection,
    [BIO]: BioSection,
    [CALL_TO_ACTION]: CallToActionSection,
    [COMMUNITY_SURVEY]: CommunitySurveySection,
    [PRIORITIES]: PrioritiesSection,
    [TRANSITION_TEAM]: TransitionTeamSection,
  };
  const footerColors = {
    [ANNOUNCEMENTS]: 'lightblue',
    [BIO]: 'white',
    [CALL_TO_ACTION]: 'white',
    [COMMUNITY_SURVEY]: 'white',
    [PRIORITIES]: 'lightblue',
    [TRANSITION_TEAM]: 'lightblue',
  };

  const searchParams = setSearchParams(location);
  const lastSection = homepageSections[homepageSections.length - 1];
  const lastSectionTheme = lastSection.backgroundTheme;

  return (
    <Layout
      location={location}
      metaDescription={metaDescription && metaDescription.metaDescription}
      metaUrl={`https://${process.env.GATSBY_HOST_DOMAIN}${path}`}
      metaTitle={`${metaTitle || siteTitle}`}
      metaImageUrl={metaImageUrl}
      fbAppID={social.fbAppID}
      twitter={social.twitter}
      locale={locale}
      navTheme="transparent"
      settings={settings}
      pageInOtherLanguages={pageInOtherLanguages}
      enLanguagePageLinks={enLanguagePageLinks}
      languageLabels={languageLabels}
      disableAlertBar={disableAlertBar}
      searchParams={searchParams}
      footerBackground={lastSectionTheme}
    >
      <HomepageHero
        title={heroTitle && heroTitle.heroTitle}
        subheading={heroSubheading}
        image={heroImage}
        action={heroCallToAction}
        slogan={heroSlogan}
        social={socialLinks}
      />
      <div>
        {homepageSections && homepageSections.map((section, index) => {
          const { id, theme: sectionTheme, sectionType } = section;
          const previousSection = index > 0 && homepageSections[(index - 1)];
          const SectionModule = sectionModules[sectionType];
          const previousSectionColor = index === 1 ? "lightblue" : previousSection.backgroundTheme;

          if (SectionModule) {
            return (
              <SectionModule
                {...section}
                key={id}
                sectionTheme={sectionTheme}
                previousSection={previousSection}
                previousSectionColor={previousSectionColor}
                firstSection={index === 0}
                sharedLabels={sharedLabels}
              />
            );
          }
          return (
            <React.Fragment />
          );
        })}
      </div>
    </Layout>
  );
};

export default ContentHomepageTemplate;

export const homepagePageQuery = graphql`
  query ContentHomepageBySlug($slug: String!, $locale: String!) {
    site {
      ...SiteMetadata
    }
    page: contentfulPage(path: { eq: $slug }, language: { eq: $locale }) {
      ...PageMetadata
      pageContent {
        ... on ContentfulPageContentHomepage {
          heroTitle {
            heroTitle
          }
          heroSubheading {
            raw
          }
          heroImage {
            gatsbyImageData(layout: FULL_WIDTH, backgroundColor: "#091f2f", quality: 100)
            description
            contentful_id
          }
          heroCallToAction {
            contentful_id
            externalLink
            linkTitle
            internalPageLink {
              path
            }
          }
          heroSlogan
          homepageSections {
            sectionType
            contentful_id
            title {
              title
            }
            description {
              raw
            }
            backgroundTheme
            sectionImagery {
              gatsbyImageData(layout: FULL_WIDTH, backgroundColor: "#091f2f", quality: 100)
              constrained: gatsbyImageData(layout: CONSTRAINED, backgroundColor: "#091f2f", quality: 100)
              description
              contentful_id
            }
            link {
              linkTitle
              externalLink
              internalPageLink {
                path
              }
            }
            contentReferences {
              ... on ContentfulPageContentNewsItem {
                id
                title
                attribution
                publicationDate(formatString: "MMM D, YYYY")
                previewImage {
                  gatsbyImageData
                  description
                }
                page {
                  path
                }
              }
              ... on ContentfulComponentAppointee {
                id
                headshot {
                  gatsbyImageData
                  description
                }
                firstName
                contentful_id
                lastName
                title
                fullBioLink {
                  linkTitle
                  internalPageLink {
                    path
                  }
                }
              }
              ... on ContentfulComponentCard {
                contentful_id
                title
                description {
                  raw
                }
                link {
                  linkTitle
                  externalLink
                  internalPageLink {
                    path
                  }
                  internalFileLink {
                    file {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    settings: contentfulGlobalSiteSettings(language: { eq: $locale }) {
      ...GlobalSiteSettings
    }
    languageLabels: contentfulGlobalSiteSettings(language: { eq: "en-US" }) {
      ...LanguageLabels
    }
  }
`;
